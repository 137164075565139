<template>
  <div>
    <div class="tile is-ancestor pt-6 mx-6 mt-6">
      <div class="tile is-vertical is-6 mx-0">
        <article>
          <h1 class="title has-text-primary-dark has-text-right mx-4">
            {{ $t("aboutusheader") }}
          </h1>
        </article>
      </div>
      <div class="tile is-vertical mt-5 is-4 has-text-left">
        <article>
          <p>{{ $t("aboutussubheader") }}</p>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical is-12">
        <article class="mx-6">
          <figure class="crop">
            <img
              class="my-6 mx-6"
              :src="require('@/assets/About/IMG_9592-Editar.jpg')"
              width="90%"
            />
          </figure>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/About/IMG_9035.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title mt-6 has-text-primary-dark has-text-left has-text-break"
          >
            {{ $t("visionheader") }}
          </h1>
          <p class="my-4 has-text-left">
            {{ $t("visiontext") }}
          </p>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title mt-6 has-text-primary-dark has-text-right has-text-break"
          >
            {{ $t("missionheader") }}
          </h1>
          <p class="my-4 has-text-right has-text-break">
            {{ $t("missiontext") }}
          </p>
        </article>
      </div>
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/About/IMG_8733.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
    </div>
    <div class="tile is-vertical mb-4">
      <h1 class="has-text-primary-dark title has-text-center">
        {{ $t("values") }}
      </h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent mx-6">
        <div class="tile is-child is-3 mx-5 my-5">
          <div class="card mx-4">
            <div class="card-image">
              <figure class="image">
                <img
                  :src="require('@/assets/Logos e ilustraciones/Icons-07.png')"
                  alt=""
                />
              </figure>
            </div>
            <footer class="card-footer mx-6">
              <p class="card-footer-item subtitle">{{ $t("value1") }}</p>
            </footer>
          </div>
        </div>
        <div class="tile is-child is-3 mx-5 my-5">
          <div class="card mx-4">
            <div class="card-image">
              <figure class="image">
                <img
                  :src="require('@/assets/Logos e ilustraciones/Icons-08.png')"
                  alt=""
                />
              </figure>
            </div>
            <footer class="card-footer mx-6">
              <p class="card-footer-item subtitle">{{ $t("value2") }}</p>
            </footer>
          </div>
        </div>
        <div class="tile is-child is-3 mx-5 my-5">
          <div class="card mx-4">
            <div class="card-image">
              <figure class="image">
                <img
                  :src="require('@/assets/Logos e ilustraciones/Icons-09.png')"
                  alt=""
                />
              </figure>
            </div>
            <footer class="card-footer mx-6">
              <p class="card-footer-item subtitle">{{ $t("value3") }}</p>
            </footer>
          </div>
        </div>
        <div class="tile is-child is-3 mx-5 my-5">
          <div class="card mx-4">
            <div class="card-image">
              <figure class="image">
                <img
                  :src="require('@/assets/Logos e ilustraciones/Icons-10.png')"
                  alt=""
                />
              </figure>
            </div>
            <footer class="card-footer mx-6">
              <p class="card-footer-item subtitle">{{ $t("value4") }}</p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style>
.crop {
  width: 100%;
  height: 40vw;
  overflow: hidden;
}
p {
  white-space: pre;
}
.card {
  background-color: whitesmoke;
}
</style>
